 .forgot-password-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
} 

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px; 
}

.input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px; 
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #4e73df;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
  background-color: #3751d0;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

