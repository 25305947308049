/* styles.module.css */

/* Reset Password Form Container */
.resetPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  width: 100%;
  background-color: #f4f6f9; /* Light background for contrast */
  padding: 0;
  margin: 0;
}

/* Reset Password Card */
.resetPasswordCard {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 350px;
  text-align: center;
}

/* Form Title */
.resetPasswordCard h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Input Fields */
.resetPasswordCard input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

/* Error Message */
.errorMessage {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Submit Button */
.resetPasswordButton {
  padding: 10px 20px;
  width: 100%;
  background-color: #4e73df;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Submit Button Hover */
.resetPasswordButton:hover {
  background-color: #3751d0;
}

/* Submit Button Disabled */
.resetPasswordButtonDisabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Loading Text */
.loadingText {
  font-size: 16px;
  color: #333;
}
