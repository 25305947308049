.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.container > div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d5d5d5;
  align-items: center;
  padding: 8px;
  padding-left: 10px;
  border-radius: 7px;
}

.chargedContainer {
  margin-bottom: 35px;
  display: flex;
  gap: 15px;
  margin-top: 25px;
}

.chargedContainer > * {
  margin-bottom: 15px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .container > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #d5d5d5;
    align-items: flex-start;
    padding: 8px;
    padding-left: 10px;
    border-radius: 7px;
  }

  .chargedContainer {
    margin-bottom: 35px;
  }

  .chargedContainer > * {
    margin-bottom: 15px;
  }
}
