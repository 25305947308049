/* styles.module.css */
.uploadCsvTestContainer {
  padding: 20px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--primary-bg-color);
  max-width: 1000px; /* Control max width */
  width: 100%;
  text-align: center;
}

.fileInput {
  display: none;
}

.fileUploadLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.fileUploadInput {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileUploadButton {
  margin-top: 15px;
  padding: 12px 15px;
  border: none;
  border-radius: 4px;
  color: var(--menu-icon-color);
  background-color: var(--button-bg-color);
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  border-radius: 20px;
}

.fileUploadButton:hover {
  background-color: var(--button-bg-color-hover);
}

.fileUploadButtonActive {
  background-color: var(--active-route-color);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.userTable {
  margin: 20px auto;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  table-layout: auto; /* Allows dynamic resizing of columns */
}

.userTable th,
.userTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  word-wrap: break-word; /* Ensures long text wraps within the cell */
}

.userTable th {
  background-color: var(--table-header-bg-color);
  color: var(--table-header-text-color);
  white-space: nowrap; /* Prevents header text from wrapping */
}

.noUsers {
  margin-top: 20px;
  font-size: 18px;
  color: #6c757d;
}

@media (max-width: 768px) {
  .uploadCsvTestContainer {
    padding: 10px;
  }

  .userTable {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .fileUploadButton {
    margin-top: 50px; /* Adjust margin on smaller screens */
  }

  .buttonContainer {
    flex-direction: column;
    gap: 15px;
  }
}
