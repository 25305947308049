.mainContainer {
  width: 90vw;
  max-width: 600px;
  margin: 20px auto;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.submitButton {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .mainContainer {
    width: 95vw;
    margin: 10px auto;
    padding: 15px;
  }
  .container {
    padding: 15px;
  }
}

/* .mainContainer {
  width: 95vw;
  margin-left: 2.5vw;
  margin-top: 2.5vh;
  background: white;
  border-radius: 7px;
  position: relative;
  overflow-x: hidden;
}
.container {
  width: 87vw;
  background-color: white;
  border: 1px solid white;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.submitButton {
  width: 100%;
  margin-top: 25vh;
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .mainContainer {
    width: 100%;
    margin-left: 0;
    margin-top: 2.5vh;
    background: white;
    border-radius: 7px;
    position: relative;
    overflow-x: hidden;
  }
  .container {
    width: 100%;
    background-color: white;
    border: 1px solid white;
    border-radius: 3px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
  }
} */
