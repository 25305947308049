.profileContainer {
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  margin: 0 auto;
  color: var(--profile-text-color);
}

.gridContainer,
.gridContainer2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  padding: 20px;
  background-color: var(--primary-bg-color);
  box-shadow: var(--container-shadow);
  border-radius: 10px;
  margin-bottom: 20px;
}

.picSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--profile-border-color);
}

.picContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fileInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoSection ul {
  list-style: none;
  padding: 0;
}

.infoSection li {
  margin-bottom: 15px;
}

.labelRow {
  display: flex;
  align-items: center;
}

.labelRow label {
  font-size: 16px;
  color: var(--profile-text-color);
  font-weight: 600;
}

.label {
  font-size: 16px;
  color: black;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.infoSection span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  color: var(--profile-text-color);
}

button.MuiButton-root {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  background-color: var(--button-bg-color);
  color: var(--profile-text-color);
}

button.MuiButton-root:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--button-bg-color-hover);
}

button.MuiButton-containedPrimary {
  background-color: var(--button-bg-color);
}

button.MuiButton-containedSecondary {
  background-color: var(--button-bg-color-hover);
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .gridContainer,
  .gridContainer2 {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .picContainer {
    width: 100px;
    height: 100px;
  }

  .infoSection ul {
    margin-bottom: 20px;
  }

  button.MuiButton-root {
    width: 100%;
    font-size: 14px;
  }
}

/* .gridContainer,
.gridContainer2 {
  width: 100%;
  background-color: #e1e1e1;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 35px;
  margin-bottom: 5vh;
}
.gridContainer {
  min-height: 35vh;
}

.gridContainer2 {
  min-height: 20vh;
}

.picSection {
  width: 15vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.picContainer {
  grid-area: pic;
  width: 10vw;
  height: 10vw;
  border-radius: 100%;
  border: 1px solid transparent;
  background-color: white;
  margin-bottom: 10px;
}

.picContainer > img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.infoSection {
  background-color: green;
}

.infoSection > ul,
.infoSection > ul > li {
  list-style-type: none;
}

.infoSection > ul > li > .label,
.infoSection > ul > li > .value {
  font-size: 15px !important;
  color: black;
}

.infoSection > ul > li > label {
  font-weight: bold;
}

.label {
  font-size: 15px !important;
  color: #1a3199;
  font-weight: bold;
}

.infoSection > ul > li > label::after {
  content: ":";
}

.infoSection > ul > li > span {
  margin-left: 2px;
}

.action {
  margin-left: 5px;
  font-size: 1px;
}

@media (max-width: 768px) {
  .gridContainer,
  .gridContainer2 {
    width: 100%;
    background-color: #e1e1e1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 5vh;
  }
  .picSection {
    width: 90%;
    padding: 5%;
  }
  .picContainer {
    width: 20vw;
    height: 20vw;
    border-radius: 100%;
  }
  .infoSection {
    padding: 5%;
  }
  .infoSection > ul > li > .label,
  .infoSection > ul > li > .value {
    font-size: 13px !important;
    color: black;
  }
} */
