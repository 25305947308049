:root {
  --primary-bg-color: #ffffff;
  --menu-bg-color: #2c3e50;
  --button-bg-color: #2c3e50;
  --button-bg-color-hover: #1abc9c;
  --menu-hover-color: #34495e;
  --menu-icon-color: #ecf0f1;
  --active-route-color: #1abc9c;
  --profile-bg-color: #34495e;
  --profile-border-color: #1abc9c;
  --profile-text-color: #ecf0f1;
  --profile-hover-bg-color: #1abc9c;
  --container-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--primary-bg);
}

.center-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vh auto;
}

.content-container {
  width: 80vw;
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.full-width {
  width: 100vw;
  padding: 0;
  margin-top: 80px;
}

.menu-icon-button {
  z-index: 3;
  position: fixed;
  left: 0;
  transition: 250ms;
}

.menu-icon-button.open {
  left: 50vw;
}

.children-table-container {
  padding: 20px;
  margin-bottom: 80px;
}

.table-wrapper {
  height: auto;
  overflow-y: auto;
  margin-top: 40px;
}

.table-container {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .center-container {
    margin: 0 65px;
  }

  .table-wrapper {
    height: 400px;
    overflow-y: scroll;
  }
}
