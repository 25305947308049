/* Make sure the container takes full height */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  padding: 0;
  margin: 0;
  background-color: white; /* White background to remove any unwanted styling */
}

/* Styling for the card */
.card {
  padding: 30px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 350px;
}

/* Link Expired Text */
.title {
  color: red;
  font-size: 24px;
  margin-bottom: 15px;
}

/* Description Text */
.message {
  color: #555;
  font-size: 16px;
  margin-bottom: 20px;
}

/* Button Styling */
.button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #4e73df;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

/* Button hover state */
.button:hover {
  background-color: #3751d0;
}
